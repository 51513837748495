import React from 'react'
import Select from 'react-select'

interface IProps {
  signd_in: boolean,
  regionList: any[],
  prefectureList: any[],
  cityList: any[],
  searchObject: any
}
const SearchPrefecture: React.FC<IProps> = props => {
  const [regionList, setRegionList] = React.useState<any[]>(props.regionList)
  const [prefectureList, setPrefectureList] = React.useState<any[]>(props.prefectureList)
  const [cityList, setCityList] = React.useState<any[]>(props.cityList)
  const [selectedRegion, setSelectedRegion] = React.useState<any>(
    () => {
      if (props.searchObject.attributes.region_name) {
        return { label: props.searchObject.attributes.region_name, value: props.searchObject.attributes.region_name }
      } else {
        return null
      }
    }
  )
  const [selectedPrefecture, setSelectedPrefecture] = React.useState<any>(
    () => {
      if (props.searchObject.attributes.prefecture_name) {
        return { label: props.searchObject.attributes.prefecture_name, value: props.searchObject.attributes.prefecture_name }
      } else {
        return null
      }
    }
  )
  const [selectedCity, setSelectedCity] = React.useState<any>(
    () => {
      if (props.searchObject.attributes.city_name) {
        return { label: props.searchObject.attributes.city_name, value: props.searchObject.attributes.city_name }
      } else {
        return null
      }
    }
  )

  React.useEffect(() => {
    if ( selectedRegion && selectedRegion.value != undefined ) {
      setPrefectureList(null)
      setCityList(null)

      fetch(`/cities?region_name=${selectedRegion.value}`, {
        method: "get"
      }).then((response) => {
        if (!response.ok) {
            throw new Error();
        }
        return response.json()
      }).then((json) => {
        setPrefectureList(json.data)
        setCityList(null)
      })
    } else if (!selectedRegion) {
      setPrefectureList(null)
      setCityList(null)
    }
  }, [selectedRegion])

  React.useEffect(() => {
    if ( selectedPrefecture && selectedPrefecture.value != undefined ) {
      setCityList(null)

      fetch(`/cities?prefecture_name=${selectedPrefecture.value}`, {
        method: "get"
      }).then((response) => {
        if (!response.ok) {
            throw new Error();
        }
        return response.json()
      }).then((json) => {
        setCityList(json.data)
      })
    } else if (!selectedPrefecture) {
      setCityList(null)
    }
  }, [selectedPrefecture])

  const onChangeRegion = async (selectItem: any) => {
    setSelectedRegion(selectItem)
    setSelectedPrefecture(null)
    setSelectedCity(null)
  }
  

  const onChangePrefecture = async (selectItem: any) => {
    setSelectedPrefecture(selectItem)
    setSelectedCity(null)
  }
  
  return (
    <div>
      
      <Select 
        isClearable
        options={regionList} 
        name="search_listing[region_name]" 
        onChange={onChangeRegion} 
        className="d-block"
        placeholder="地域を入力"
        defaultValue={selectedRegion}
        />
      {prefectureList &&
        <Select 
          isClearable
          options={prefectureList} 
          name="search_listing[prefecture_name]" 
          onChange={onChangePrefecture} 
          className="d-block mt-16-8px"
          placeholder="都道府県を入力"
          defaultValue={selectedPrefecture}
          />
      }
      {cityList &&
        <Select 
          isClearable
          options={cityList} 
          name="search_listing[city_name]" 
          className="d-block mt-16-8px"
          placeholder="市区町村を選択"
          defaultValue={selectedCity} />
      }
    </div>
  )
}

export default SearchPrefecture
